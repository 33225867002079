// awsSignature.js
import AWS from 'aws-sdk';
import aws4 from 'aws4';

const getAwsSignature = (apiUrl, method, payload) => {
    const credentials = new AWS.Credentials({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });

    const region = 'ap-south-1'; 
    const signedRequest = aws4.sign({
        host: new URL(apiUrl).host,
        method: method,
        url: apiUrl,
        path: new URL(apiUrl).pathname,
        headers: {
           
        },
        body: JSON.stringify(payload),
    }, credentials, { region: region });

    return signedRequest.headers;
};

export default getAwsSignature;
