import React, { useState, useEffect } from "react";
import moment from "moment";
import getAwsSignature from "../awsSignature";
import {
  Table,
  Tag,
  Collapse,
  Button,
  Modal,
  message,
  Upload,
  Form,
  Input,
  DatePicker,
  Select,
  Alert,
  Space,
  Tooltip,
  Popconfirm,
  Spin,
} from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Panel } = Collapse;

const Request = () => {
  const [Edata, setEdata] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const apiUrl = "https://mc0xaoe0u3.execute-api.ap-south-1.amazonaws.com/v1/ccQuery";
      const method = "GET";
      const headers = await getAwsSignature(apiUrl, method);
      const response = await fetch(apiUrl, {
        method,
        headers,
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const result = await response.json();
      setEdata(result);
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("Error fetching data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      render: (_, record) => (
        <Collapse>
          <Panel header={`Name : ${record.name} `} key={record._id}>
            <p>{`Message : ${record.message}`}</p>
            
          </Panel>
        </Collapse>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },

    {
      title: "Email ID",
      dataIndex: "email",
      key: "email",
    },

  ];

  return (
    <div>
      <h4 style={{ color: "#3d6ed7" }}>Request</h4>
      <Spin spinning={loading}>
        <Table dataSource={Edata} columns={columns} pagination={{ pageSize: 5 }} />
      </Spin>
    </div>
  );
};

export default Request ;
