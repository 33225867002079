import React, { useState, useEffect } from "react";
import getAwsSignature from "../awsSignature";
import { Table, Tag, Button, Modal, Form, Input, Select, Space, Popconfirm, message, Spin } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
const { TextArea } = Input;
const { Option } = Select;

const Broadcast = () => {
  const [form] = Form.useForm();
  const [Edata, setEdata] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  // Function to fetch data from the API
  const fetchData = async () => {
    try {
      setLoading(true);
      const apiUrl = "https://n25r995oaj.execute-api.ap-south-1.amazonaws.com/v1/ccBroadcastMsg";
      const api = `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_STAGE}/broadcast`;
   
console.log(api);
      const method = "GET";
      const headers = await getAwsSignature(apiUrl, method);
      const response = await fetch(apiUrl, {
        method,
        headers,
      });
      const result = await response.json();
      setEdata(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle the edit operation
  const handleEdit = async (record) => {
    try {
      setLoading(true);
      const apiUrl = `https://n25r995oaj.execute-api.ap-south-1.amazonaws.com/v1/ccBroadcastMsg/${record._id}`;
      const method = "GET";
      const headers = await getAwsSignature(apiUrl, method);
      const response = await fetch(apiUrl, {
        method,
        headers,
      });
      const result = await response.json();
      setModalVisible(true);
      setSelectedRecord(result);
      // Populate the form with data from the selected record
      form.setFieldsValue({
        notification: result.notification,
        isActive: result.isActive,
      });
    } catch (error) {
      console.error("Error fetching data for edit:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to clear form fields
  const handleClear = () => {
    form.resetFields();
    setSelectedRecord(null);
  };

  // Function to close the modal
  const handleClose = () => {
    setModalVisible(false);
    form.resetFields();
    setSelectedRecord(null);
  };

  // Function to handle form submission
  const handleSubmit = async () => {
    try {
      // Check if there's already an active broadcast
      if (form.getFieldValue('isActive')) {
        const activeBroadcast = Edata.find((item) => item.isActive);
        if (activeBroadcast && (!selectedRecord || selectedRecord._id !== activeBroadcast._id)) {
          message.warning("Only one broadcast can be active at a time. Please deactivate the existing one.");
          return;
        }
      }

      const formData = form.getFieldsValue();
      // Validate if notification field is not empty
      if (!formData.notification) {
        message.warning("Please enter all field before submitting the form.");
        return;
      }
      
      const apiUrl = selectedRecord
        ? `https://n25r995oaj.execute-api.ap-south-1.amazonaws.com/v1/ccBroadcastMsg/${selectedRecord._id}`
        : "https://n25r995oaj.execute-api.ap-south-1.amazonaws.com/v1/ccBroadcastMsg";
      const method = selectedRecord ? "PATCH" : "POST";

      const headers = await getAwsSignature(apiUrl, method, formData);

      const response = await fetch(apiUrl, {
        method,
        headers,
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (result.message === "Document Created successfully" || result.message === "Document Updated successfully") {
        message.success("Data updated successfully");
        fetchData();
      } else {
        console.error("Error updating data:", result);
      }

      // Close the modal and reset the form after successful submission
      setModalVisible(false);
      form.resetFields();
      setSelectedRecord(null);
    } catch (error) {
      console.error(error);
    }
  };

  // Function to handle the delete operation
  const handleDelete = async (_id) => {
    try {
      setLoading(true);
      const apiUrl = `https://n25r995oaj.execute-api.ap-south-1.amazonaws.com/v1/ccBroadcastMsg/${_id}`;
      const method = "DELETE";
      const headers = await getAwsSignature(apiUrl, method);
      const response = await fetch(apiUrl, {
        method,
        headers,
      });
      const result = await response.json();

      if (result.message === "Document deleted successfully") {
        message.success("Data deleted successfully");
        fetchData();
      } else {
        console.error("Error deleting data:", result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Table columns configuration
  const columns = [
    {
      title: "Notification",
      dataIndex: "notification",
      key: "notification",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text) => (
        <Tag color={text === true ? "green" : "grey"}>
          {text === true ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          {/* Edit button */}
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          />
          {/* Delete button */}
          <Popconfirm
            title="Are you sure you want to delete this item?"
            onConfirm={() => handleDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // Component rendering
  return (
    <div>
      {/* Display the title */}
      <h4 style={{ color: "#3d6ed7" }}>Broadcast</h4>
      {/* Display a loading spinner while data is being fetched */}
      <Spin spinning={loading}>
        {/* Display the data in a table */}
        <Table dataSource={Edata} columns={columns} pagination={{ pageSize: 5 }} />
      </Spin>

      {/* Display a button to add a new broadcast */}
      <div style={{ marginTop: "16px", textAlign: "right" }}>
        <Button
          type="primary"
          onClick={() => setModalVisible(true)}
          className="add-button"
          shape="circle"
          size="large"
          style={{
            background: "#3d6ed7",
            borderColor: "#3d6ed7",
            fontSize: "24px",
            height: "48px",
            width: "48px",
          }}
          icon={<PlusOutlined style={{ fontSize: "30px" }} />}
        />
      </div>

      {/* Modal for adding/editing broadcast messages */}
      <Modal
        title={selectedRecord ? "Edit Broadcast Message" : "Add Broadcast Message"}
        visible={modalVisible}
        onCancel={handleClose}
        footer={[
          // Clear button
          <Button key="clear" onClick={handleClear}>
            Clear
          </Button>,
          // Submit/Update button
          <Button key="submit" type="primary" onClick={handleSubmit}>
            {selectedRecord ? "Update" : "Submit"}
          </Button>
        ]}
        width={600}
        height={400}
      >
        {/* Form for entering/editing broadcast details */}
        <Form form={form}>
          {/* Textarea for entering/editing notification */}
          <Form.Item
            label="Notification"
            name="notification"
            rules={[
              { required: true, message: "Please enter the notification!" },
            ]}
          >
            <TextArea
              showCount
              maxLength={100}
            />
          </Form.Item>
          {/* Dropdown for selecting the status */}
          <Form.Item
            label="Status"
            name="isActive"
            rules={[{ required: true, message: "Please select the status!" }]}
          >
            <Select
              style={{ width: "150px" }}
            >
              <Option value={true}>Active</Option>
              <Option value={false}>Inactive</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Broadcast;
