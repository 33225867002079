// ImageCardAndTable.js

import React from 'react';
import { WarningOutlined } from '@ant-design/icons';
import "./Home.css";
import logo from "./info.png"

const ImageCard = ({ title, description, color }) => {
  // Format description inside the component
  const formattedDescription = description.replace(/\n/g, '<br>');

  return (
    <div className="card" style={{ width: '250px', height: '150px', backgroundColor: color ,textAlign:'justify'}}>
      <h5>{title}</h5>
      <p dangerouslySetInnerHTML={{ __html: formattedDescription }} />
    </div>
  );
};

const TableWithSections = () => {
  return (
    <>
      <h4 className="guidelines">Guidelines</h4>
      <div className='top-bar'>
       <h5>Important Instruction</h5>
        </div>
      <div className="info-container">

        <h5 className="warning-title">
        <img src={logo} style={{ width: '60px', height: '60px' }} />
         
          
        </h5>
        <br />
        <p className="warning-text">
          Please be informed that before adding, modifying, or deleting any data from any section; make sure you have respective image(s), documents, and video files before proceeding.<br/>
          The dimension (width x height) of the images varies in every section. 
        </p>
      </div>

      {/* Example Image Cards */}
      <div className="card-container">
        <ImageCard
          title="Image Dimension"
          description="Canvas = 1500x500 <br/> Mass Struggle = 800x600 <br/>Gallery = 400x600"
          color="#ECECEC"
        />
        <ImageCard
          title="Image Quality"
          description="Minimum PPI = 150+<br/> Proffered PPI = 300+<br/>Avoid PPI =< 100"
          color="#CCFFCC"
        />
        <ImageCard
          title="File Type"
          description="Image = .png, .jpg , .jpeg<br/>Document = .pdf<br/>Video = .mp4"
          color="#CCCCFF"
        />
        <ImageCard
          title="File Size"
          description="Image = 20MB (Max)<br/>PDF = 10MB (Max)<br/>Video = 50MB (Max)"
          color="#FFFFCC"
        />
      </div>
    </>
  );
};

const ImageCardAndTable = () => {
  return (
    <div>
      <TableWithSections />
    </div>
  );
};

export default ImageCardAndTable;
