



import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./resources/global.css";
import Home from "./pages/Home";
import MassStruggle from "./pages/home/massStruggle";
import MassStruggle1 from "./pages/home/massStruggle1";
import NewsCanvas from "./pages/home/newsCanvas";
import Statement from "./pages/home/statement";
import Login from "./pages/Login";
import Media from "./pages/home/media";
import LeaderSpeaks from "./pages/home/leaderSpeaks";
import Photos from "./pages/gallery/photos";
import Videos from "./pages/gallery/videos";
import CRequest from "./pages/communication/crequest";
import Proletarian_Era from "./pages/organ/proletarian_era";
import Sarbahara_Dristikon from "./pages/organ/sarbahara_dristikon";
import ProtectedRoute from "./Components/ProtectedRoute";
import ShibdasGhosh from "./pages/publication/shibdas_ghosh";
import Nihar_Mukreejee from "./pages/publication/nihar_mukreejee";
import Provash_Ghosh from "./pages/publication/provash_ghosh";


import PublicRoute from "./Components/PublicRoute";
import Spinner from "./Components/spinner";

import { useSelector } from "react-redux";
import "process/browser";

import Broadcast from "./pages/home/broadcast";
import { Request } from '.';
function App() {
  const { loading } = useSelector((state) => state.alerts);
  return (
    <div>
      {loading && <Spinner />}

      <BrowserRouter>
        <Routes>
          <Route
            path="/home/broadcast"
            element={
              <ProtectedRoute>
                <Broadcast />
              </ProtectedRoute>
            }
          />
          <Route
            path="/home/newsCanvas"
            element={
              <ProtectedRoute>
                <NewsCanvas />
              </ProtectedRoute>
            }
          />
          <Route
            path="/home/media"
            element={
              <ProtectedRoute>
                <Media />
              </ProtectedRoute>
            }
          />
          <Route
            path="/home/massStruggle1"
            element={
              <ProtectedRoute>
                <MassStruggle1 />
              </ProtectedRoute>
            }
          />
          <Route
            path="/home/leaderSpeaks"
            element={
              <ProtectedRoute>
                <LeaderSpeaks/>
              </ProtectedRoute>
            }
          />
          <Route
            path="/publication/shibdasGhosh"
            element={
              <ProtectedRoute>
                <ShibdasGhosh/>
              </ProtectedRoute>
            }
          />
            <Route
            path="/publication/provashGhosh"
            element={
              <ProtectedRoute>
                <Provash_Ghosh/>
              </ProtectedRoute>
            }
          />
            <Route
            path="/publication/niharMukreejee"
            element={
              <ProtectedRoute>
                <Nihar_Mukreejee/>
              </ProtectedRoute>
            }
          />
          
          
          <Route
            path="/publication/shibdasGhosh"
            element={
              <ProtectedRoute>
                <ShibdasGhosh/>
              </ProtectedRoute>
            }
          />
          
          <Route
            path="/organs/proletarianEra"
            element={
              <ProtectedRoute>
                <Proletarian_Era />
              </ProtectedRoute>
            }
          />
           <Route
            path="/organs/sarbaharaDristikon"
            element={
              <ProtectedRoute>
                <Sarbahara_Dristikon/>
              </ProtectedRoute>
            }
          />
          <Route
            path="/home/statement"
            element={
              <ProtectedRoute>
                <Statement />
              </ProtectedRoute>
            }
          />
          <Route
            path="gallery/photos"
            element={
              <ProtectedRoute>
                <Photos/>
              </ProtectedRoute>
            }
          />
          <Route
            path="gallery/videos"
            element={
              <ProtectedRoute>
                <Videos/>
              </ProtectedRoute>
            }
          />
           <Route
            path="/communication/request"
            element={
              <ProtectedRoute>
                <CRequest/>
              </ProtectedRoute>
            }
          />


          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
