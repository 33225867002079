import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../Redux/alertsSlice';
import { useNavigate } from 'react-router-dom';
import './login.css';
import logo from "./suci_logo.png"

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      dispatch(showLoading());

      // const response = await axios.post("https://jwt-validator.onrender.com/api/user/login", values);
      const response = await axios.post("https://a3dfqtmice.execute-api.ap-south-1.amazonaws.com/user/login", values);
     
      dispatch(hideLoading());
      

      if (response.data.success) {
        message.success(response.data.message);
        localStorage.setItem("token", response.data.data);
        navigate("/"); // Redirect to the "/" route
      } else {
        message.error(response.data.message);
      }


      // const response = await axios.post("https://m0xb1jc6x2.execute-api.ap-south-1.amazonaws.com/suci-jwt/generate", values);
      // console.log(response.data)

      // dispatch(hideLoading());

      // if (response.data) {
      //   message.success(response.data.message);
      //   document.cookie = `token=${response.data.token}`
      //   navigate("/"); // Redirect to the "/" route
      // } else {
      //   message.error(response.data.message);
      // }


    } catch (error) {
      dispatch(hideLoading());
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
      <div className="top-section">
        <img src={logo}></img>
        <h1>SUCI (C)</h1>
          <h2>Web Console</h2>
        </div>

        <div className="bottom-section">
          <Form
            form={form}
            name="loginForm"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            style={{ width: '250px', margin: 'auto' }}
          >
            <Form.Item
              name="userID"
              rules={[{ required: true, message: 'Please input your User ID!' }]}
            >
              <Input placeholder="User ID" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your Password!' }]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{
                  width: '70%',
                  background: 'rgba(0, 188, 212, 1)',
                  
                  border: 'none',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                }}
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
