// Import missing dependencies
import React, { useState, useEffect } from "react";
import getAwsSignature from "../awsSignature";
import moment from "moment";
import AWS from "aws-sdk";
import { FilePdfOutlined } from "@ant-design/icons";
import {
  Table,
  Tag,
  Collapse,
  Button,
  Modal,
  message,
  Upload,
  Form,
  Input,
  DatePicker,
  Select,
  Alert,
  Space,
  Tooltip,
  Popconfirm,
  Spin,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  PictureOutlined, // Add missing import
} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";

const { MonthPicker, RangePicker } = DatePicker;
// Missing definition for smallImageFileList and largeImageFileList
const initialImageFileList = [];
const initialdocFileList = [];

const { Panel } = Collapse;
const { TextArea } = Input;
const { Option } = Select;

const initialFormState = {
  title: "",
  venue: "",
  date: null,
  sequence: null,
  isActive: true,
  imageFile: null,
};

// Initialize smallImageFileList and largeImageFileList
const initialImageFileListState = [];
const initialdocFileListState = [];

const initialAlertTypeState = {
  info: true,
  success: false,
  error: false,
};

const Provash_ghosh = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isImageEditMode, setIsImageEditMode] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const [isVerifySuccess, setIsVerifySuccess] = useState(false);
  const [Edata, setEdata] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [ImageFileList, setImageFileList] = useState(initialImageFileListState);
  const [docFileList, setdocFileList] = useState(initialdocFileListState);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [alertType, setAlertType] = useState(initialAlertTypeState);
  const [loading, setLoading] = useState(false);

  const categories = ["Book", "Article"];
  const volumes = ["Volume1", "Volume2", "Volume3", "Volume4", "N/A"];
  const tags = [
    "Selected Work",
    "Popular",
    "Best Seller",
    "Recommended",
    "N/A",
  ];
  const languages = ["English", "Hindi", "Bengali"];

  const openPdfLink = () => {
    // Access the PDF link from selectedRecord.doc
    const pdfLink = selectedRecord.doc;
    window.open(pdfLink, "_blank");
  };

  const [wordCountShort, setWordCountShort] = useState(0);
  const [wordCountLong, setWordCountLong] = useState(0);
  const maxShortDescWords = 18;
  const maxLongDescWords = 300;
  const [showWarningShort, setShowWarningShort] = useState(false);
  const [showWarningLong, setShowWarningLong] = useState(false);
  const handleTextAreaChange = (field, e) => {
    const text = e.target.value;
    const words = text.trim().split(/\s+/);
    const limitedWords =
      field === "short_desc"
        ? words.slice(0, maxShortDescWords)
        : words.slice(0, maxLongDescWords);

    if (
      limitedWords.length <= maxShortDescWords ||
      limitedWords.length <= maxLongDescWords
    ) {
      if (field === "short_desc") {
        setWordCountShort(limitedWords.length);
        setShowWarningShort(false);
      } else if (field === "long_desc") {
        setWordCountLong(limitedWords.length);
        setShowWarningLong(false);
      }
    } else {
      // If the word count exceeds the limit, show warning
      if (field === "short_desc") {
        setShowWarningShort(true);
      } else if (field === "long_desc") {
        setShowWarningLong(true);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const apiUrl =
        "https://39vossabvk.execute-api.ap-south-1.amazonaws.com/v1/ccPublication";
      const method = "GET";
      const headers = await getAwsSignature(apiUrl, method);
      const response = await fetch(apiUrl, {
        method,
        headers,
      });
      const result = await response.json();
      // Filter the data based on the "type" property
      const filteredData = result.filter(
        (item) => item.writer === "Provash Ghosh"
      );

      // Update the state only with items having "type": "photos"
      setEdata(filteredData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFileUpload = async (file,folderName) => {
    if (!file) {
      console.error("No file selected");
      return;
    }

    const credentials = new AWS.Credentials({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });

    const bucketName = "suci-public";
    
    const region = "ap-south-1";

    const s3 = new AWS.S3({ credentials, region });

    const params = {
      Bucket: bucketName,
      Key: `${folderName}/${file.name}`,
      Body: file,
      ContentType: file.type,
    };

    try {
      const data = await s3.upload(params).promise();
      console.log("File uploaded successfully:", data.Location);
      return data.Location;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const handleVerify = async () => {
    try {
      // Check if both image and document files are present
      if (ImageFileList.length === 0 || docFileList.length === 0) {
        message.warning(
          "Please select both image and document files to verify."
        );
        return;
      }

      const verifyImage = (fileList, allowedExtensions, maxSize, minSize) => {
        const file = fileList[0].originFileObj;
        const fileSize = file.size / 1024 / 1024;
        const extension = file.name.split(".").pop().toLowerCase();

        if (
          fileSize < maxSize &&
          fileSize > minSize &&
          allowedExtensions.includes(extension)
        ) {
          return true;
        } else {
          return {
            sizeError:
              fileSize >= maxSize
                ? "File size exceeds the maximum limit."
                : "File size is below the minimum limit.",
            typeError: !allowedExtensions.includes(extension)
              ? "Invalid file type."
              : "",
          };
        }
      };

      const imageVerificationResult = verifyImage(
        ImageFileList,
        ["png", "jpg", "jpeg"],
        20,
        0
      );
      const docVerificationResult = verifyImage(docFileList, ["pdf"], 20, 0);

      const handleVerificationResult = (verificationResult) => {
        if (verificationResult === true) {
          return {
            success: true,
            error: false,
          };
        } else {
          return {
            success: false,
            error: true,
            sizeError: verificationResult.sizeError,
            typeError: verificationResult.typeError,
          };
        }
      };

      const imageResult = handleVerificationResult(imageVerificationResult);
      const docResult = handleVerificationResult(docVerificationResult);

      if (imageResult.success && docResult.success) {
        message.success(
          "Files verified successfully. You can now upload them."
        );
        setIsVerifySuccess(true);
        setAlertType({
          info: false,
          success: true,
          error: false,
        });
      } else {
        let errorMessage =
          "File verification failed. Please check the following issues:\n";
        if (imageResult.error) {
          errorMessage += `- Image: ${imageResult.sizeError} ${imageResult.typeError}\n`;
        }
        if (docResult.error) {
          errorMessage += `- Document: ${docResult.sizeError} ${docResult.typeError}\n`;
        }

        message.error(errorMessage);
        setAlertType({
          info: false,
          success: false,
          error: true,
        });
        setIsImageUploaded(false);
        setIsVerifySuccess(false);
      }
    } catch (error) {
      console.error("Error verifying files:", error);
    }
  };

  const handleUpload = async () => {
    try {
      if (ImageFileList.length > 0 && docFileList.length > 0) {
        const ImageUrl = await handleFileUpload(
          ImageFileList[0].originFileObj,
          "publication/image"
        );
        const docUrl = await handleFileUpload(
          docFileList[0].originFileObj,
          "publication/doc"
        );

        message.success(`Images uploaded successfully`);
        setIsSubmitEnabled(true);
        setIsImageUploaded(true);
      } else {
        message.warning("Please select both small and large images to upload.");
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      message.error("Error uploading images. Please try again.");
    }
  };

  const handleSubmit = async () => {
    try {
      const formValues = form.getFieldsValue();

      // Ensure all required fields are filled
      if (
        !formValues.title ||
        !formValues.date ||
        !formValues.lang ||
     
        !formValues.category
      ) {
        message.warning("Please enter all fields before submitting the form.");
        return;
      }

      // Ensure an image is uploaded
      if (!isImageUploaded) {
        message.warning("Please upload an image before submitting the form.");
        return;
      }

      // Handle image upload and get the image URL

      const ImageUrl = isEditMode
        ? selectedRecord.image_small
        : await handleFileUpload(ImageFileList[0].originFileObj,"publication/image");
      const docUrl = isEditMode
        ? selectedRecord.image_large
        : await handleFileUpload(docFileList[0].originFileObj,"publication/doc");
      const updatedData = {
        title: formValues.title,
        date: formValues.date.format("DD/MM/YYYY"),
        image: ImageUrl,
        doc: docUrl,
        category: formValues.category,
        lang: formValues.lang,
       
        writer: "Provash Ghosh",
     
      };

      let apiUrl, method;

      if (isEditMode || isImageEditMode) {
        // If it's in edit mode, make a PATCH request
        apiUrl = `https://39vossabvk.execute-api.ap-south-1.amazonaws.com/v1/ccPublication/${selectedRecord._id}`;
        method = "PATCH";
      } else {
        // If it's not in edit mode, make a POST request
        apiUrl =
          "https://39vossabvk.execute-api.ap-south-1.amazonaws.com/v1/ccPublication";
        method = "POST";
      }

      const headers = await getAwsSignature(apiUrl, method, updatedData);

      const response = await fetch(apiUrl, {
        method,
        headers,
        body: JSON.stringify(updatedData),
      });

      const result = await response.json();

      if (
        result.message === "Document Updated successfully" ||
        result.message === "Document Created successfully"
      ) {
        message.success(
          isEditMode ? "Data updated successfully" : "Data created successfully"
        );
        fetchData(); // Refresh the data after successful update
      } else {
        console.error("Error updating/creating data:", result);
        message.error("Error updating/creating data. Please try again.");
      }

      handleClose(); // Close the modal after updating
    } catch (error) {
      console.error(error);
      message.error("Error submitting the form. Please try again.");
    }
  };

  const handleEdit = async (record) => {
    try {
      setIsVerifySuccess(true);
      setIsEditMode(true);
      setIsSubmitEnabled(true);
      setIsImageUploaded(true);
      setLoading(true);
      setSelectedRecord(record);
      const apiUrl = `https://39vossabvk.execute-api.ap-south-1.amazonaws.com/v1/ccPublication/${record._id}`;
      const method = "GET";
      const headers = await getAwsSignature(apiUrl, method);
      const response = await fetch(apiUrl, {
        method,
        headers,
      });
      const result = await response.json();
      setModalVisible(true);

      form.setFieldsValue({
        title: result.title,
        date: moment(result.date, "DD/MM/YYYY"),

        category: result.category,
        lang: result.lang,
        
        writer: "Provash Ghosh",
       
      });
    } catch (error) {
      console.error("Error fetching data for edit:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (_id) => {
    try {
      setLoading(true);
      const apiUrl = `https://39vossabvk.execute-api.ap-south-1.amazonaws.com/v1/ccPublication/${_id}`;
      const method = "DELETE";
      const headers = await getAwsSignature(apiUrl, method);
      const response = await fetch(apiUrl, {
        method,
        headers,
      });
      const result = await response.json();

      if (result.message === "Document deleted successfully") {
        message.success("Data deleted successfully");
        fetchData();
      } else {
        console.error("Error deleting data:", result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (file, type) => {
    if (type === "small") {
      setImageFileList([file]);
      setIsImageUploaded(false);
    } else if (type === "large") {
      setdocFileList([file]);
      setIsImageUploaded(false);
    }
  };
  const handleClear = () => {
    setIsEditMode(false);
    setIsSubmitEnabled(false);
    setIsVerifySuccess(false);
    setAlertType(initialAlertTypeState);
    setImageFileList([]);
    setdocFileList([]);
    setWordCountShort(0);
    setWordCountLong(0);
    setShowWarningShort(false);
    setShowWarningLong(false);
    form.resetFields();
    setIsImageUploaded(false);
  };
  const handleClose = () => {
    setIsEditMode(false);
    setIsSubmitEnabled(false);
    setIsVerifySuccess(false);
    setAlertType(initialAlertTypeState);
    setImageFileList([]);
    setdocFileList([]);
    setWordCountShort(0);
    setWordCountLong(0);
    setShowWarningShort(false);
    setShowWarningLong(false);
    form.resetFields();
    setIsImageUploaded(false);
    setModalVisible(false);
  };

  const openModal = () => {
    message.info(
      "Please select Image and Document,Verify and Upload to fill other sections.",
      10
    );
    setModalVisible(true);
  };

  const columns = [
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      render: (_, record) => (
        <Collapse>
          <Panel
            header={`Title : ${record.title}  Date : ${moment(
              record.date,
              "DD/MM/YYYY"
            ).format("DD/MM/YYYY")}`}
            key={record._id}
          >
            <p>{`Writer : ${record.writer}`}</p>
            <p>{`Language : ${record.lang}`}</p>
           
            <p>{`Category : ${record.category}`}</p>
            
          </Panel>
        </Collapse>
      ),
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (text) => (
        <a
          href={text}
          target="_blank"
          rel="noopener noreferrer"
          title="Click to open in the browser"
        >
          <img
            src={text}
            alt="image"
            style={{ width: "30px", height: "30px", objectFit: "cover" }}
          />
        </a>
      ),
    },

    {
      title: "Document",
      dataIndex: "doc",
      key: "doc",
      render: (text) => (
        <a
          href={text}
          target="_blank"
          rel="noopener noreferrer"
          title="Click to open in the browser"
        >
          <FilePdfOutlined style={{ fontSize: "30px", color: "red" }} />
        </a>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          {/* Edit button */}
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          />
          {/* Delete button */}
          <Popconfirm
            title="Are you sure you want to delete this item?"
            onConfirm={() => handleDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h4 style={{ color: "#3d6ed7" }}>Com. Provash Ghosh</h4>
      <Table
        dataSource={Edata}
        columns={columns}
        pagination={{ pageSize: 5 }}
      />

      <div style={{ marginTop: "16px", textAlign: "right" }}>
        <Button
          type="primary"
          onClick={openModal}
          className="add-button"
          shape="circle"
          size="large"
          style={{
            background: "#3d6ed7",
            borderColor: "#3d6ed7",
            fontSize: "24px",
            height: "48px",
            width: "48px",
          }}
          icon={<PlusOutlined style={{ fontSize: "30px" }} />}
        />
      </div>

      <Modal
        title="Com.Provash Ghosh"
        visible={modalVisible}
        onCancel={handleClose}
        footer={[
          <Button key="clear" onClick={handleClear}>
            Clear
          </Button>,
          <Button
            key="verify"
            onClick={handleVerify}
            disabled={isVerifySuccess && isSubmitEnabled}
          >
            Verify Files
          </Button>,
          <Button
            key="upload"
            onClick={handleUpload}
            disabled={!isVerifySuccess || isSubmitEnabled}
          >
            Upload Files
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            disabled={!isSubmitEnabled}
          >
            Submit
          </Button>,
        ]}
        width={950}
      >
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                width: "45%",
                border: "1px dotted grey",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <h6>Details</h6>
              <Form form={form} initialValues={initialFormState}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    { required: true, message: "Please enter the title!" },
                  ]}
                >
                  <Input disabled={!isImageUploaded} showCount maxLength={60} />
                </Form.Item>

                <Form.Item
                  label="Date"
                  name="date"
                  rules={[
                    { required: true, message: "Please enter the date!" },
                  ]}
                >
                  <DatePicker format="DD/MM/YYYY" disabled={!isImageUploaded} />
                </Form.Item>

                {/* Category dropdown */}
                <Form.Item
                  label="Category"
                  name="category"
                  rules={[
                    { required: true, message: "Please select a category!" },
                  ]}
                >
                  <Select disabled={!isImageUploaded}>
                    {categories.map((category) => (
                      <Option key={category} value={category}>
                        {category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {/* Volume dropdown */}
               

                {/* Tag dropdown */}
                
                {/* Language dropdown */}
                <Form.Item
                  label="Language"
                  name="lang"
                  rules={[
                    { required: true, message: "Please select a language!" },
                  ]}
                >
                  <Select disabled={!isImageUploaded}>
                    {languages.map((language) => (
                      <Option key={language} value={language}>
                        {language}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </div>

            <div
              style={{
                width: "45%",
                border: "1px dotted grey",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <h6>File Upload</h6>
              {isEditMode ? (
                <>
                  <img
                    src={selectedRecord.image}
                    alt="image"
                    style={{ width: "50%", height: "auto" }}
                  />
                  <a href="#" onClick={openPdfLink}>
                    <FilePdfOutlined
                      style={{ fontSize: "50px", color: "red" }}
                    />
                  </a>

                  <div style={{ marginTop: "16px", display: "flex" }}>
                    <Button
                      onClick={() => {
                        setIsEditMode(false);
                        setIsVerifySuccess(false);
                        setIsImageUploaded(false);
                        setIsSubmitEnabled(false);
                        setIsImageEditMode(true);
                      }}
                    >
                      Change Files
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <h8>Image Upload</h8>
                  <ImgCrop aspect={1 / 1}>
                    <Upload
                      listType="picture-card"
                      fileList={ImageFileList}
                      onChange={({ fileList: newFileList }) => {
                        setImageFileList(newFileList);
                        setIsImageUploaded(false);
                      }}
                      showRemoveIcon={false}
                      customRequest={({ onSuccess }) => onSuccess("ok")}
                    >
                      {ImageFileList.length < 1 ? (
                        <div>
                          <PictureOutlined
                            style={{ fontSize: "48px", color: "#3498eb" }}
                          />
                        </div>
                      ) : null}
                    </Upload>
                  </ImgCrop>

                  <h8>Document Upload</h8>

                  <Upload
                    listType="picture-card"
                    fileList={docFileList}
                    onChange={({ fileList: newFileList }) => {
                      setdocFileList(newFileList);
                      setIsImageUploaded(false);
                    }}
                    showRemoveIcon={false}
                    customRequest={({ onSuccess }) => onSuccess("ok")}
                  >
                    {docFileList.length < 1 ? (
                      <div>
                        <FilePdfOutlined
                          style={{ fontSize: "48px", color: "#ff0000" }}
                        />
                      </div>
                    ) : null}
                  </Upload>

                  <Space direction="vertical" style={{ width: "100%" }}>
                    {!isEditMode && isSubmitEnabled && (
                      <>
                        <Alert
                          message="Files uploaded successfully"
                          type="success"
                          showIcon
                        />
                      </>
                    )}
                    {!isEditMode && !isSubmitEnabled && (
                      <>
                        {alertType.info && (
                          <Alert
                            message="Note"
                            description={
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    "* Allowed Image files .png .jpg .jpeg <br> * Image size should be less than 20MB <br> * Document  should be in .pdf",
                                }}
                              />
                            }
                            type="info"
                            showIcon
                          />
                        )}
                        {alertType.success && (
                          <Alert
                            message="Verification Successful"
                            description={
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    "* Allowed Image files .png .jpg .jpeg <br> * Image size should be less than 20MB <br> * Document  should be in .pdf",
                                }}
                              />
                            }
                            type="success"
                            showIcon
                          />
                        )}
                        {alertType.error && (
                          <Alert
                            message="Image size or format doesn't match"
                            description={
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    "* Allowed Image files .png .jpg .jpeg <br> * Image size should be less than 20MB <br> * Document  should be in .pdf",
                                }}
                              />
                            }
                            type="error"
                            showIcon
                          />
                        )}
                      </>
                    )}
                  </Space>
                </>
              )}
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default Provash_ghosh;
