// DefaultLayout.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteUser } from "../Redux/userSlice";
import { Badge, Menu } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined ,WindowsOutlined} from '@ant-design/icons';
import "../resources/layout1.css";

const { SubMenu } = Menu;

function DefaultLayout({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(deleteUser());
    navigate("/login");
  };

  const adminMenu = [
    {
      name: (
        <>
          <WindowsOutlined />
          

          {" Dashboard"}
        </>
      ),
      path: "/",
    },

    {
      name: "Home",
      subMenu: [
        
        { name: "Broadcast", path: "/home/broadcast" },
        { name: "News Canvas", path: "/home/newsCanvas" },
        { name: "Mass Struggle", path: "/home/massStruggle1" },
        { name: "Statement", path: "/home/statement" },
        { name: "Media", path: "/home/media" },
        { name: "Leader Speaks", path: "/home/leaderSpeaks" },
      ],
    },
    {
      name: "Publication",
      subMenu: [
        {
          name: "Com. Shibdas Ghosh",
          path: "/publication/shibdasGhosh",
          
        },
        {
          name: "Com. Provash Ghosh",
          path: "/publication/provashGhosh",
          
        },
        {
          name: "Com. Nihar Mukreejee",
          path: "/publication/niharMukreejee",
          
        },
      ],
    },
    {
      name: "Organs",
      subMenu: [
        {
          name: "Proletarian Era (English)",
          path: "/organs/proletarianEra",
        },
        {
          name: "Sarbahara Dristikon (Hindi)",
          path: "/organs/sarbaharaDristikon",
        },
      ],
    },
    {
      name: "Gallery",
      subMenu: [
        { name: "Photos", path: "/gallery/photos" },
        { name: "Videos", path: "/gallery/videos" },
      ],
    },
    {
      name: "Query",
      subMenu: [
        { name: "Request", path: "/communication/request" },
        // { name: "Members", path: "/home/communication/members" },
      ],
    },
  ];
  const noUserMenu = [
    {
      name: "Home",
      path: "/login",
    },
  ];

  const menuToBeRendered =
    user?.role === "CC"
      ? adminMenu
      : user?.role === "Modrator"
      ? adminMenu
      : user?.role === "Admin"
      ? adminMenu
      : noUserMenu;

  const role = user?.role;
  const activeRoute = window.location.pathname;

  const getMenuItems = (menuItems) => {
    return menuItems.map((item, index) => (
      <Menu.Item
        key={item.path}
        onClick={() => {
          if (item.path === "/logout") {
            handleLogout();
          } else {
            navigate(item.path);
          }
        }}
      >
        {item.name}
      </Menu.Item>
    ));
  };

  const getSubMenuItems = (subMenu) => {
    return subMenu.map((item) => (
      <SubMenu key={item.path} title={item.name}>
        {item.subMenu ? getSubMenuItems(item.subMenu) : getMenuItems([item])}
      </SubMenu>
    ));
  };

  return (
    <div className="layout-parent">
     <div className="header">
        <div className="sidebar-header">
          <h4>SUCI (C)</h4>
        </div>
        <div className="sidebar-header">
          <h5>Web Console</h5>
        </div>
        <div className="body-right">
          <div>
            <p className="usersname">
              {user?.name}
              &nbsp;<span className="badge badge-primary">{role}</span>
              &nbsp;&nbsp;
              <Badge
                count={<i className="ri-logout-box-r-line"></i>}
                onClick={handleLogout}
              />
            </p>
          </div>
        </div>
      </div>

      <div className="body">
        <div className="sidebar">
        <div className="sidebar-top">
          
          <Menu
            mode="inline"
            defaultSelectedKeys={[activeRoute]}
            defaultOpenKeys={menuToBeRendered.map((item) => item.path)}
            style={{ backgroundColor:"#fafafa"}}
            >
            {menuToBeRendered.map((item) =>
              item.subMenu ? (
                <SubMenu key={item.path} title={item.name}>
                  {item.name === "Home" ||
                  item.name === "Organs" ||
                  item.name === "Gallery" ||
                  item.name === "Query" ||
                  item.name === "Publication"
                    ? getMenuItems(item.subMenu)
                    : getSubMenuItems(item.subMenu)}
                </SubMenu>
              ) : (
                <Menu.Item
                  key={item.path}
                  onClick={() => {
                    if (item.path === "/logout") {
                      handleLogout();
                    } else {
                      navigate(item.path);
                    }
                  }}
                  style={{
                    fontWeight: activeRoute === item.path ? "bold" : "normal",
                  }}
                >
                  {item.name}
                </Menu.Item>
              )
            )}
          </Menu>
          </div>

          <div className="sidebar_down">
            <p>V1.0 Build#30012024</p>
          </div>
        
        </div>

        <div className="content">{children}</div>
      </div>
    </div>
  );
}

export default DefaultLayout;
