import React, { useState, useEffect } from "react";
import getAwsSignature from "../awsSignature";
import { Table, Tag, Collapse, Button, Modal, message, Upload, Form, Input, DatePicker, Select } from "antd";
import ImgCrop from 'antd-img-crop';
import moment from "moment";
import AWS from "aws-sdk";
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const { Panel } = Collapse;

const MassStruggle = () => {
  const [Edata, setEdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [form, setForm] = useState({
    title: "",
    date: null,
    image_large: null,
    image_small: null,
    long_desc: "",
    short_desc: "",
    isActive: true,
    sequence: null,
  });
  const [smallImageFileList, setSmallImageFileList] = useState([]);
  const [largeImageFileList, setLargeImageFileList] = useState([]);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isVerifySuccess, setIsVerifySuccess] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [noteColor, setNoteColor] = useState("grey");

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: "Sequence",
      dataIndex: "sequence",
      key: "sequence",
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      render: (_, record) => (
        <Collapse>
          <Panel header={`Title : ${record.title}  Date : ${moment(record.date, "DD/MM/YYYY").format("DD/MM/YYYY")}`} key={record._id}>
            <p>{`Short description : ${record.short_desc}`}</p>
            <p>{`Long description : ${record.long_desc}`}</p>
          </Panel>
        </Collapse>
      ),
    },
    {
      title: "Image (Small)",
      dataIndex: "image_small",
      key: "image_small",
      render: (text) => (
        <a
          href={text}
          target="_blank"
          rel="noopener noreferrer"
          title="Click to open in the browser"
        >
          <img
            src={text}
            alt="News"
            style={{ width: "30px", height: "30px", objectFit: "cover" }}
          />
        </a>
      ),
    },
    {
      title: "Image (Large)",
      dataIndex: "image_large",
      key: "image_large",
      render: (text) => (
        <a
          href={text}
          target="_blank"
          rel="noopener noreferrer"
          title="Click to open in the browser"
        >
          <img
            src={text}
            alt="News"
            style={{ width: "40px", height: "30px", objectFit: "cover" }}
          />
        </a>
      ),
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text) => (
        <Tag color={text === true ? "green" : "grey"}>
          {text === true ? "Active" : "Inactive"}
        </Tag>
      ),
    },
  ];

  const fetchData = async () => {
    try {
      const apiUrl = "https://j0rxho9e3e.execute-api.ap-south-1.amazonaws.com/v1/ccMassStruggle";
      const method = "GET";
      const headers = await getAwsSignature(apiUrl, method);
      const response = await fetch(apiUrl, {
        method,
        headers,
      });
      const result = await response.json();

      if (Array.isArray(result)) {
        setEdata(result);
      } else {
        console.error("Invalid data format received from the API:", result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (file,folderName) => {
    if (!file) {
      console.error("No file selected");
      return;
    }

    try {
      const credentials = new AWS.Credentials({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      });

      const bucketName = process.env.REACT_APP_S3_BUCKET_NAME;
      
      const region = process.env.REACT_APP_AWS_REGION;

      const s3 = new AWS.S3({ credentials, region });

      const params = {
        Bucket: bucketName,
        Key: `${folderName}/${file.name}`,
        Body: file,
        ContentType: file.type,
      };

      const data = await s3.upload(params).promise();

      console.log("File uploaded successfully:", data.Location);

      return data.Location;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

 

  const handleVerify = async () => {
    setIsVerifySuccess(true);
    setIsImageUploaded(true);
    setIsSubmitEnabled(true);
    setNoteColor("green");
  };

  const handleUpload = async () => {
    try {
      if (smallImageFileList.length > 0 && largeImageFileList.length > 0) {
        const smallImageUrl = await handleFileUpload(smallImageFileList[0].originFileObj, "massStruggle/image/small");
        const largeImageUrl = await handleFileUpload(largeImageFileList[0].originFileObj, "massStruggle/image/large");

        message.success(`Images uploaded successfully`);
        setIsImageUploaded(true);
      } else {
        message.warning("Please select both small and large images to upload.");
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      message.error("Error uploading images. Please try again.");
    }
  };

  const handleSubmit = async () => {
    try {
      if (!isImageUploaded) {
        message.warning("Please upload images before submitting the form.");
        return;
      }

      const smallImageUrl = await handleFileUpload(smallImageFileList[0].originFileObj,"massStruggle/image/small");
      const largeImageUrl = await handleFileUpload(largeImageFileList[0].originFileObj,"massStruggle/image/small");

      const formData = {
        title: form.title,
        date: moment(form.date).format("DD/MM/YYYY"),
        image_large: largeImageUrl,
        image_small: smallImageUrl,
        long_desc: form.long_desc,
        short_desc: form.short_desc,
        sequence: form.isActive ? Number(form.sequence) : 0,
        isActive: form.isActive,
      };

      console.log(formData);
      const apiUrl = "https://j0rxho9e3e.execute-api.ap-south-1.amazonaws.com/v1/ccMassStruggle";
      const method = "POST";

      const headers = await getAwsSignature(apiUrl, method, formData);

      const response = await fetch(apiUrl, {
        method,
        headers,
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (result.message === "Document Created successfully") {
        console.log("Data updated successfully");
        message.success("Data updated successfully");
        fetchData();
      } else {
        console.error("Error updating data:", result);
        message.error("Error updating data. Please try again.");
      }

      setModalVisible(false);
      setForm({
        title: "",
        date: null,
        image_large: null,
        image_small: null,
        long_desc: "",
        short_desc: "",
        isActive: true,
        sequence: null,
      });
      setIsImageUploaded(false);
    } catch (error) {
      console.error(error);
      message.error("Error submitting the form. Please try again.");
    }
  };

  const handleImageChange = (file, type) => {
    if (type === "small") {
      setSmallImageFileList([file]);
      setIsImageUploaded(false);
    } else if (type === "large") {
      setLargeImageFileList([file]);
      setIsImageUploaded(false);
    }
  };

  const handleClear = () => {
    setModalVisible(false);
    setForm({
      title: "",
      date: null,
      image_large: null,
      image_small: null,
      long_desc: "",
      short_desc: "",
      isActive: true,
      sequence: null,
    });
    setIsImageUploaded(false);
  };

  const openModal = () => {
    message.info("Please upload images and verify to fill other sections.");
    setModalVisible(true);
  };

  const handleInputChange = (field, value) => {
    setForm((prevForm) => ({
      ...prevForm,
      [field]: value,
    }));
  };

  return (
    <div>
      <h4 style={{ color: "#3d6ed7" }}>Mass Struggle</h4>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Table
          dataSource={Edata}
          columns={columns}
          pagination={{ pageSize: 5 }}
          expandable={{ defaultExpandAllRows: true }}
        />
      )}

      <div style={{ marginTop: "16px", textAlign: "right" }}>
      <Button
    type="primary"
    onClick={openModal}
    className="add-button"
    shape="circle"
    size="large"
    style={{
      background: "#3d6ed7",
      borderColor: "#3d6ed7",
      fontSize: "24px", // Adjust the font size of the button
      height: "48px",   // Adjust the height of the button
      width: "48px",    // Optionally, adjust the width of the button
    }}
    icon={<PlusOutlined style={{ fontSize: "30px" }} />} // Adjust the font size of the icon
  />
      </div>

      <Modal
        title="Create Mass Struggle"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="clear" onClick={handleClear}>
            Clear
          </Button>,
          <Button key="verify" onClick={handleVerify}>
            Verify
          </Button>,
         
          <Button
            key="upload"
            onClick={handleUpload}
            disabled={!isVerifySuccess || !isImageUploaded}
          >
            Upload
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            disabled={!isSubmitEnabled}
          >
            Submit
          </Button>,
        ]}
        width={800}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              width: "45%",
              border: "1px dotted grey",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <h6>Details</h6>
    <Form
      initialValues={{
        title: form.title,
        date: form.date ? moment(form.date) : null,
        short_desc: form.short_desc,
        long_desc: form.long_desc,
        isActive: form.isActive,
        sequence: form.sequence,
      }}
      onFinish={handleSubmit}
      onFinishFailed={(errorInfo) => console.log("Failed:", errorInfo)}
    >
      <Form.Item label="Title" name="title">
        <Input
          disabled={!isImageUploaded}
          onChange={(e) => handleInputChange("title", e.target.value)}
        />
      </Form.Item>

      <Form.Item label="Date" name="date">
        <DatePicker
          disabled={!isImageUploaded}
          onChange={(date, dateString) => handleInputChange("date", dateString)}
        />
      </Form.Item>

      <Form.Item label="Short Description" name="short_desc">
        <Input
          disabled={!isImageUploaded}
          onChange={(e) => handleInputChange("short_desc", e.target.value)}
        />
      </Form.Item>

      <Form.Item label="Long Description" name="long_desc">
        <TextArea
          disabled={!isImageUploaded}
          onChange={(e) => handleInputChange("long_desc", e.target.value)}
        />
      </Form.Item>

      <Form.Item label="Status" name="isActive">
        <Select
          disabled={!isImageUploaded}
          onChange={(value) => handleInputChange("isActive", value)}
        >
          <Select.Option value={true}>Active</Select.Option>
          <Select.Option value={false}>Inactive</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label="Sequence" name="sequence">
        <Input
          type="number"
          disabled={!isImageUploaded}
          onChange={(e) => handleInputChange("sequence", e.target.value)}
        />
      </Form.Item>

      
    </Form>
  </div>

          <div
            style={{
              width: "45%",
              border: "1px dotted grey",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <h6>Image Upload</h6>

            <h8>Small Image</h8>
            <ImgCrop aspect={1 / 1}>
              <Upload
                listType="picture-card"
                fileList={smallImageFileList}
                onChange={({ fileList: newFileList }) => {
                  setSmallImageFileList(newFileList);
                  setIsImageUploaded(false);
                  setNoteColor("grey");
                }}
                beforeUpload={() => false}
              >
                {smallImageFileList.length < 1 && "+ Upload"}
              </Upload>
            </ImgCrop>

           

            <h8>Large Image</h8>
            <ImgCrop aspect={16 / 9}>
              <Upload
                listType="picture-card"
                fileList={largeImageFileList}
                onChange={({ fileList: newFileList }) => {
                  setLargeImageFileList(newFileList);
                  setIsImageUploaded(false);
                  setNoteColor("grey");
                }}
                beforeUpload={() => false}
              >
                {largeImageFileList.length < 1 && "+ Upload"}
              </Upload>
            </ImgCrop>

           

            <div style={{ color: noteColor }}>
              <p>NOTE* </p>
              <p> Image size should be less than 20 Mb</p>
              <p>The Image should be .PNG .JPG .JPEG </p>
            </div>
          </div>
        </div>
      </Modal>
    </div>

  );
};

export default MassStruggle;