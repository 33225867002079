import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setUser } from "../Redux/userSlice";
import { showLoading, hideLoading } from "../Redux/alertsSlice";
import DefaultLayout from "./DefaultLayout1";


function ProtectedRoute({ children }) {
  const { user } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.alerts);
  const dispatch = useDispatch();
  const navigate = useNavigate();
 
  const getUser = async () => {
    try {
      dispatch(showLoading());

      const response = await axios.get("https://a3dfqtmice.execute-api.ap-south-1.amazonaws.com/user/currentUser", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      // const response = await axios.get("https://m0xb1jc6x2.execute-api.ap-south-1.amazonaws.com/suci-jwt/verify", {
      //   headers: {
      //     Cookie: `token=${token}`,  
      //   },
      // });
      dispatch(hideLoading());
      if (response.data.success) {
        console.log("inside success");
        dispatch(setUser(response?.data.user));
      } else {
        localStorage.clear();
        navigate("/login");
      }
    } catch (error) {
      dispatch(hideLoading());
      localStorage.clear();
      navigate("/login");
    }
  };

  useEffect(() => {
    if (!user) {
      getUser();
    }
  }, [user]);

  if (localStorage.getItem("token")) {
    return <DefaultLayout>{children}</DefaultLayout>;
  } else {
    return <Navigate to="/" />;
  }
}

export default ProtectedRoute;

