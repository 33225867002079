import React, { useState, useEffect } from "react";
import moment from "moment";
import AWS from "aws-sdk";
import { Table, Tag, Collapse, Button, Modal, message, Upload, Form, Input, DatePicker, Select, Alert, Space,Tooltip, Popconfirm,Spin} from "antd";

import getAwsSignature from "../awsSignature";
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import { PictureOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
const { TextArea } = Input;
const { Option } = Select;

const initialFormState = {
  title: "",
  venue: "",
  date: null,
  sequence: null,
  isActive: true,
  imageFile: null,
};

const initialFileListState = [];

const initialAlertTypeState = {
  info: true,
  success: false,
  error: false,
};

const NewsCanvas = () => {
  const [selectedSequence, setSelectedSequence] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [isImageEditMode, setIsImageEditMode] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [existingSequences, setExistingSequences] = useState([]);
  const [isVerifySuccess, setIsVerifySuccess] = useState(false);
  const [Edata, setEdata] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  // const [form, setForm] = useState(initialFormState);
  const [fileList, setFileList] = useState(initialFileListState);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [alertType, setAlertType] = useState(initialAlertTypeState);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const apiUrl = "https://km1420w2i2.execute-api.ap-south-1.amazonaws.com/v1/ccNewsCanvas";
      const method = "GET";
      const headers = await getAwsSignature(apiUrl, method);
      const response = await fetch(apiUrl, {
        method,
        headers,
      });
      const result = await response.json();
      const formattedData = result.map((item) => ({
        ...item,
        date: moment(item.date, "DD/MM/YYYY").format("DD/MM/YYYY"),
        isActive: item.isActive ? "Active" : "Inactive",
      }));
      setEdata(formattedData);

      const sequences = formattedData.map((item) => item.sequence);
      setExistingSequences(sequences);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFileUpload = async (file) => {
    if (!file) {
      console.error("No file selected");
      return;
    }

    const credentials = new AWS.Credentials({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });

    const bucketName = "suci-public";
    const folderName = "home/newsCanvas/images";
    const region = "ap-south-1";

    const s3 = new AWS.S3({ credentials, region });

    const params = {
      Bucket: bucketName,
      Key: `${folderName}/${file.name}`,
      Body: file,
      ContentType: file.type,
    };

    try {
      const data = await s3.upload(params).promise();
      console.log("File uploaded successfully:", data.Location);
      return data.Location;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const handleVerify = async () => {
    try {
      if (fileList.length > 0) {
        const file = fileList[0].originFileObj;
        const fileSize = file.size / 1024 / 1024;
        const allowedExtensions = ['png', 'jpg', 'jpeg'];
        const extension = file.name.split('.').pop().toLowerCase();

        if (fileSize < 20 && allowedExtensions.includes(extension)) {
          message.success("Image verified successfully. You can now upload it.");
          
          setIsVerifySuccess(true);
          setAlertType({
            info: false,
            success: true,
            error: false,
          });
        } else {
          let errorMessage = "Image verification failed. Allowed file types: " + allowedExtensions.join(', ') + ". Image size should be less than 20MB.";
          message.error(errorMessage);
          setAlertType({
            info: false,
            success: false,
            error: true,
          });
          setIsImageUploaded(false);
          setIsVerifySuccess(false);
        }
      } else {
        message.warning("Please select an image to verify.");
        setIsImageUploaded(false);
        setIsVerifySuccess(false);
      }
    } catch (error) {
      console.error("Error verifying image:", error);
    }
  };

  const handleUpload = async () => {
    try {
      if (fileList.length > 0) {
        const imageUrl = await handleFileUpload(fileList[0].originFileObj);
        message.success("Image uploaded successfully");
        setIsImageUploaded(true);
        setIsSubmitEnabled(true);
      } else {
        message.warning("Please select an image to upload.");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      message.error("Error uploading image. Please try again.");
    }
  };

  const handleSubmit = async () => {
    try {
      const formValues = form.getFieldsValue();
  
      // Ensure all required fields are filled
      if (!formValues.title || !formValues.venue || !formValues.date) {
        message.warning("Please enter all fields before submitting the form.");
        return;
      }
  
      // Ensure an image is uploaded
      if (!isImageUploaded) {
        message.warning("Please upload an image before submitting the form.");
        return;
      }
  
      // Handle image upload and get the image URL
      const imageUrl = isEditMode ? selectedRecord.image : await handleFileUpload(fileList[0].originFileObj);
  
      const updatedData = {
        image: imageUrl,
        date: (formValues.date).format("DD/MM/YYYY"),
        title: formValues.title,
        venue: formValues.venue,
        sequence: formValues.isActive ? Number(formValues.sequence) : 0,
        isActive: formValues.isActive,
      };
  
      let apiUrl, method;
  
      if (isEditMode||isImageEditMode) {
        // If it's in edit mode, make a PATCH request
        apiUrl = `https://km1420w2i2.execute-api.ap-south-1.amazonaws.com/v1/ccNewsCanvas/${selectedRecord._id}`;
        method = "PATCH";
      } else {
        // If it's not in edit mode, make a POST request
        apiUrl = "https://km1420w2i2.execute-api.ap-south-1.amazonaws.com/v1/ccNewsCanvas";
        method = "POST";
      }
  
      const headers = await getAwsSignature(apiUrl, method, updatedData);
  
      const response = await fetch(apiUrl, {
        method,
        headers,
        body: JSON.stringify(updatedData),
      });
  
      const result = await response.json();
  
      if (result.message === "Document Updated successfully" || result.message === "Document Created successfully") {
        message.success(isEditMode ? "Data updated successfully" : "Data created successfully");
        fetchData(); // Refresh the data after successful update
      } else {
        console.error("Error updating/creating data:", result);
        message.error("Error updating/creating data. Please try again.");
      }
  
      handleClose(); // Close the modal after updating
    } catch (error) {
      console.error(error);
      message.error("Error submitting the form. Please try again.");
    }
  };
  
  const handleEdit = async (record) => {
    try {
      setIsVerifySuccess(true)
      setIsEditMode(true);
      setIsSubmitEnabled(true); 
      setIsImageUploaded(true);
      setLoading(true);
      setSelectedRecord(record);
      const apiUrl = `https://km1420w2i2.execute-api.ap-south-1.amazonaws.com/v1/ccNewsCanvas/${record._id}`;
      const method = "GET";
      const headers = await getAwsSignature(apiUrl, method);
      const response = await fetch(apiUrl, {
        method,
        headers,
      });
      const result = await response.json();
      setSelectedSequence(result.sequence)
      setModalVisible(true);
     
      form.setFieldsValue({
        title: result.title,
        venue: result.venue,
        date: moment(result.date, "DD/MM/YYYY"),
        sequence: result.sequence,
        isActive: result.isActive,
      });
    } catch (error) {
      console.error("Error fetching data for edit:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleDelete = async (_id) => {
    try {
      setLoading(true);
      const apiUrl = `https://km1420w2i2.execute-api.ap-south-1.amazonaws.com/v1/ccNewsCanvas/${_id}`;
      const method = "DELETE";
      const headers = await getAwsSignature(apiUrl, method);
      const response = await fetch(apiUrl, {
        method,
        headers,
      });
      const result = await response.json();

      if (result.message === "Document deleted successfully") {
        message.success("Data deleted successfully");
        fetchData();
      } else {
        console.error("Error deleting data:", result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (file) => {
    setFileList([file]);
    setIsImageUploaded(false);
  };

  const handleClear = () => {
    setSelectedSequence("")
    setIsSubmitEnabled(false);
    setIsVerifySuccess(false);
    setAlertType(initialAlertTypeState);
    setFileList(initialFileListState);
    
    form.resetFields();
    setIsImageUploaded(false);
  };
  const handleClose = () => {
    setSelectedSequence("")
    setIsImageEditMode(false);
    setIsEditMode(false);
    setIsSubmitEnabled(false);
    setIsVerifySuccess(false);
    setAlertType(initialAlertTypeState);
    setFileList(initialFileListState);
    setModalVisible(false)
    form.resetFields();
    setIsImageUploaded(false);
  };

  const openModal = () => {
    message.info("Please select an image, verify it, and upload it to fill in the remaining sections.", 10);
    setModalVisible(true);
  };

  const columns = [
    {
      title: "Sequence",
      dataIndex: "sequence",
      key: "sequence",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Venue",
      dataIndex: "venue",
      key: "venue",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (text) => (
        <a
          href={text}
          target="_blank"
          rel="noopener noreferrer"
          title="Click to open in the browser"
        >
          <img
            src={text}
            alt="News"
            style={{ width: "60px", height: "20px", objectFit: "cover" }}
          />
        </a>
      ),
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text) => (
        <Tag color={text === "Active" ? "green" : "grey"}>{text}</Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          {/* Edit button */}
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          />
          {/* Delete button */}
          <Popconfirm
            title="Are you sure you want to delete this item?"
            onConfirm={() => handleDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h4 style={{ color: "#3d6ed7" }}>News Canvas</h4>
      <Table dataSource={Edata} columns={columns} pagination={{ pageSize: 5 }} />

      <div style={{ marginTop: "16px", textAlign: "right" }}>
        <Button
          type="primary"
          onClick={openModal}
          className="add-button"
          shape="circle"
          size="large"
          style={{
            background: "#3d6ed7",
            borderColor: "#3d6ed7",
            fontSize: "24px",
            height: "48px",
            width: "48px",
          }}
          icon={<PlusOutlined style={{ fontSize: "30px" }} />}
        />
      </div>

      <Modal
        title="News Canvas"
        visible={modalVisible}
        onCancel={handleClose}
        footer={[
          <Button key="clear" onClick={handleClear}>
            Clear
          </Button>,
          <Button key="verify" onClick={handleVerify}disabled={isVerifySuccess && isSubmitEnabled}>
            Verify Image
          </Button>,
          <Button key="upload" onClick={handleUpload} disabled={!isVerifySuccess||isSubmitEnabled}>
            Upload Image
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            disabled={!isSubmitEnabled}
          >
            Submit
          </Button>,
        ]}
        width={950}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              width: "45%",
              border: "1px dotted grey",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <h6>Details</h6>
            <Form form={form} initialValues={initialFormState}>
  <Form.Item
    label="Title"
    name="title"
    rules={[{ required: true, message: "Please enter the title!" }]}
  >
    <Input
      disabled={!isImageUploaded}
      showCount
      maxLength={60}
    />
  </Form.Item>
  <Form.Item
    label="Venue"
    name="venue"
    rules={[{ required: true, message: "Please enter the venue!" }]}
  >
    <Input
      disabled={!isImageUploaded}
      showCount
      maxLength={40}
    />
  </Form.Item>
  
  <Form.Item
    label="Date"
    name="date"
    rules={[{ required: true, message: "Please enter the date!" }]}
  >
    <DatePicker
      format="DD/MM/YYYY"
      showTime
      disabled={!isImageUploaded}
    />
  </Form.Item>
  <Form.Item
    label="Status"
    name="isActive"
    rules={[{ required: true, message: "Please select the status!" }]}
  >
    <Select
      style={{ width: "150px" }}
      disabled={!isImageUploaded}
    >
      <Option value={true}>Active</Option>
      <Option value={false}>Inactive</Option>
    </Select>
  </Form.Item>
  <Form.Item
                  label="Sequence"
                  name="sequence"
                  rules={[{ required: true, message: "Please enter the sequence!" }]}
                >
                  
                    <Select
                      style={{ width: "100px" }}
                      value={selectedSequence}
                      onChange={(value) => {
                        form.setFieldsValue({ sequence: value });
                        setSelectedSequence(value);
                      }}
                      disabled={!isImageUploaded}
                    >
                      {[...Array(11).keys()].map((number) => (
                        <Select.Option key={number} value={number} disabled={existingSequences.includes(number) && number !== 0}>

                          {number}
                        </Select.Option>
                      ))}
                    </Select>
                 
                  <div style={{ marginTop: '8px', color: 'gray' }}>
        This is the only available slot for sequence selection. Make the other records inactive to choose another sequence.
      </div>
                </Form.Item>

</Form>


 </div>
 <div style={{ width: "45%", border: "1px dotted grey", borderRadius: "10px", padding: "10px" }}>
  <h6>Image Upload</h6>
  {isEditMode ? (
    <>
    <img src={selectedRecord.image} alt="News" style={{ width: "100%", height: "auto" }} />
    <div style={{ marginTop: '16px', display: 'flex' }}>
      
      <Button onClick={() => { setIsEditMode(false); setIsVerifySuccess(false);setIsImageUploaded(false);setIsSubmitEnabled(false);setIsImageEditMode(true)}}>Change Image</Button>

    </div>
  </>
  ) : (
    <ImgCrop aspect={21/7}>
   <Upload
  listType="picture-card"
  fileList={fileList}
  onChange={({ fileList: newFileList }) => {
    setFileList(newFileList);
    setIsImageUploaded(false);
  }}
  showRemoveIcon={false}
  customRequest={({ onSuccess }) => onSuccess('ok')} // Dummy function to avoid actual upload
>
  {fileList.length < 1 ? (
    <div>
      <PictureOutlined style={{ fontSize: '48px', color: "#3498eb" }} />
    </div>
  ) : null}
</Upload>
    </ImgCrop>
  )}
<Space direction="vertical" style={{ width: '100%' }}>
{!isEditMode && isSubmitEnabled && (
  <>
    <Alert message="Image uploaded successfully" type="success" showIcon />
    <Alert message="Fill in other fields and submit the form" type="info" showIcon />
  </>
)}
{!isEditMode && !isSubmitEnabled && (
  <>
    {alertType.info && (
      <Alert
        message="Note"
        description={
          <div dangerouslySetInnerHTML={{ __html: "* Allowed files .png .jpg .jpeg <br> * Image size should be less than 20MB" }} />
        }
        type="info"
        showIcon
      />
    )}
    {alertType.success && (
      <Alert
        message="Verification Successful"
        description={
          <div dangerouslySetInnerHTML={{ __html: "* Allowed files .png .jpg .jpeg <br> * Image size should be less than 20MB" }} />
        }
        type="success"
        showIcon
      />
    )}
    {alertType.error && (
      <Alert
        message="Image size or format doesn't match"
        description={
          <div dangerouslySetInnerHTML={{ __html: "* Allowed files .png .jpg .jpeg <br> * Image size should be less than 20MB" }} />
        }
        type="error"
        showIcon
      />
    )}
  </>
)}
</Space>
           

            
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default NewsCanvas;
